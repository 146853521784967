<template>
	<div class="justify-center page-block">
		<template v-if="Auth.isAuthenticated">
			<div class="">
				<div class="row">
					<div class="header">
						User Profiles
					</div>
				</div>
				<div class="row mb-3">
					<div v-if="$can('view', 'superuser')" class="col-3">
						<router-link to="/superuser">
							Super User Dashboard
						</router-link>
					</div>
					<div v-if="$can('view', 'merchant')" class="col-3">
						<router-link to="/merchant">
							Merchant Dashboard
						</router-link>
					</div>
					<div class="col-3">
		
					</div>
					<div class="col-3">
	
					</div>
				</div>

				<!-- <div class="row">
					<div class="header">
						Registration Views
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-3">
						<div  v-if="$can('view', 'merchant')">
							Coming Soon
						</div>
						<router-link v-if="$can('view', 'superuser')" to="/registration">
							Registration - Broken
						</router-link>
					</div>
					<div class="col-3">
						
					</div>
					<div class="col-3">
						
					</div>
					<div class="col-3">
						
					</div>
				</div> -->

				<v-row>
					<div class="header">
						e-Commerce
					</div>
				</v-row>
				<v-row>
					<v-col cols="3" v-if="$can('view', 'merchant')">
						<router-link :to="{ name: 'Home'}">
							Website Home
						</router-link>
					</v-col>
					<v-col cols="4" v-if="envVars">
						<div v-if="envVars.NODE_ENV === 'development'" >
							<div v-if="$can('view', 'merchant')">
								<a target="_blank"
									:href="`http://${GET_MERCHANT_PROFILE.domain}.patientpaymentportal.com/payment`">
									Hosted Payment Page
								</a>
							</div>
						</div>
					</v-col>
					
					<v-col cols="4" v-else class="w-full">
						<div v-if="$can('view', 'merchant')">
							<a target="_blank"
								:href="`http://${GET_MERCHANT_PROFILE.domain}.patientpaymentportal.com/payment`">
								Hosted Payment Page
							</a>
						</div>
					</v-col>
					<v-col cols="4" v-if="$can('view', 'superuser')" >
						<router-link :to="{ name: 'Transaction Receipt' }">
							Transaction Receipt
						</router-link>
					</v-col>
	
				</v-row>
				<!-- <div class="row justify-end mt-10">
					<div class="col-4">
						<v-btn
							width="50%"
							color="primary"
							@click="testButton">
							Test
						</v-btn>
					</div>
				</div> -->

			</div>

		</template>

		<template v-else>
			<v-carousel
				cycle
				:height="innerHeight"
				hide-delimiter-background
				show-arrows-on-hover
			>
				<v-carousel-item>
					<v-sheet
						class="backgroundImage1"
						height="100%"
					>
						<v-row
							class="fill-height"
							align="center"
							justify="center"
						>
							<div class="display-3">
								Log in to get started...
							</div>
						</v-row>
					</v-sheet>
				</v-carousel-item>

				<v-carousel-item>
					<v-sheet
						class="backgroundImage2"
						height="100%"
					>
						<v-row
							class="fill-height"
							align="center"
							justify="center"
						>
							<div class="display-3">
								Log in to get started...
							</div>
						</v-row>
					</v-sheet>
				</v-carousel-item>

				<v-carousel-item>
					<v-sheet
						class="backgroundImage3"
						height="100%"
					>
						<v-row
							class="fill-height"
							align="center"
							justify="center"
						>
							<div class="display-3">
								Log in to get started...
							</div>
						</v-row>
					</v-sheet>
				</v-carousel-item>

				<v-carousel-item>
					<v-sheet
						class="backgroundImage4"
						height="100%"
					>
						<v-row
							class="fill-height"
							align="center"
							justify="center"
						>
							<div class="display-3">
								Log in to get started...
							</div>
						</v-row>
					</v-sheet>
				</v-carousel-item>

			</v-carousel>
		</template>
		<!-- <div class="row">
			<div class="col-3">
				<v-btn
					@click="testButton">
					Test
				</v-btn>
			</div>
		</div> -->

	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'AdminHomeView',
  components: {
  },
  mixins: [

  ],
  data () {
    return {
			// Main Data
			envVars: null,

    }
  },
	methods: {
		testButton () {
			// console.log("this.Auth.isAuthenticated",this.Auth.isAuthenticated)
		}

	},
	computed: {
		...mapState(["Auth"]),
		...mapGetters(["GET_USER_PROFILE", "GET_MERCHANT_PROFILE"]),
		innerHeight () {
			var height = window.innerHeight
			// console.log('height', height)
			return height - 60
		}

	},
	created () {
		// console.log('Home mounted process.env',process.env)
		this.$emit('navHome', true)
		this.envVars = process.env
	}
}
</script>

<style lang="scss" scoped>

</style>
