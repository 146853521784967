<template>
	<div>
		<admin-home />	
	</div>
</template>

<script>

//Components
import AdminHome from "@/views/admin-portal/admin-view"

	export default {
		name: 'AdminHomePage',

		metaInfo: { title: 'Admin Home' },

		components: {
			AdminHome
		},

		props: {
			id: {
				type: String,
				default: 'home',
			},
		},
	}
</script>
